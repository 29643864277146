
export default {
  name: 'TitleColorDuo',

  props: {
    tag: { type: String, default: 'h2' },
    text: { type: String, default: '' },
    negative: { type: Boolean, default: false },
  },
}
